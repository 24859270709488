<template>
  <div class="p-3">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Uyelik Giriş Yöntemi" description="Aktif Olacak Üyelik Sistemini Belirleyiniz.">
              <v-select
                v-model="form.uyelik"
                :options="uyelikYonetimi"
                :reduce="(sabit) => sabit.value"
                label="title"
                multiple
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" v-if="form.uyelik.includes('google')">
            <validation-provider name="Google ID" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Google ID">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  v-model="form.google"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
            <b-link href="https://console.cloud.google.com/" target="_blank"> Google git > </b-link>
          </b-col>
          <b-col cols="12" v-if="form.uyelik.includes('facebook')">
            <validation-provider name="Facebook ID" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Facebook ID">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  v-model="form.facebook"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
                <b-link href="https://developers.facebook.com/" target="_blank"> Facebook git > </b-link>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="pt-1">
          <b-col cols="12" md="6">
            <div class="d-flex">
              <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
                <i class="fad fa-save pr-2"></i>
                {{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}
              </b-button>
              <b-button squared size="lg" variant="danger">
                <i class="fad fa-trash"></i>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import { langs } from '@/libs/languages';
import store from '@/store';

export default defineComponent({
  components: {
    vSelect,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.form = ref({
      _id: null,
      uyelik: [],
      google: null,
      facebook: null,
    });
    expo.uyelikYonetimi = ref([
      {
        value: 'local',
        title: 'Local',
      },
      {
        value: 'google',
        title: 'Google',
      },
      {
        value: 'facebook',
        title: 'Facebook',
      },
    ]);

    const handlerConfig = async () => {
      context.emit('show', true);
      await store.dispatch('uyelikYonetimiGetir').then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      context.emit('show', false);
    };

    handlerConfig();

    // expo.handlerDilChange = (event) => {
    //   let selectLang = [];
    //   event.forEach((element) => {
    //     selectLang.push(diller.find((x) => x.lang == element));
    //   });
    //   expo.form.value.diller = selectLang;
    // };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('uyelikYonetimiEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(expo.form.value.k_no == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          context.emit('show', false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
